<template>
  <div class="SubmitInvoice width-100">
    <base-invoice-create
      @cancel="goBack"
      @updated="goBack"
      :user-is-client="userIsClient"
      :invoice="invoice"
    />

    <!-- BANKING MODAL (CLIENT AND DESKTOP ONLY) -->
    <transition name="Transition__opacity-fade">
      <base-modal v-if="clientWithoutBankModal && mobile.isMobile && !isCurrentPageBanking">
        <template v-slot:label>
          Welcome to Bobtail! Please set up your bank information.
        </template>
        <template v-slot:actions>
          <base-button
            @click="goToClientBanking"
            class="bg-blue fc-white"
          >
            Add bank account
          </base-button>
        </template>
      </base-modal>
    </transition>
  </div>
</template>

<script>
// Components
import BaseButton from '../../components/base-button.vue'
import BaseInvoiceCreate from '../../components/base-invoice-create.vue'
import BaseModal from '../../components/base-modal.vue'

export default {
  name: 'SubmitInvoice',

  components: {
    BaseButton,
    BaseInvoiceCreate,
    BaseModal,
  },

  props: {
    userIsClient: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    clientWithoutBankModal () {
      return this.$store.getters.clientWithoutBankModal
    },

    invoice () {
      return this.$store.getters.invoice
    },

    isCurrentPageBanking () {
      return this.$route.name === 'dashboard-client-banking'
    },

    mobile () {
      return this.$store.getters.mobile
    },
  },

  mounted () {
    // catch-all for odd-browser-behaviors causing scroll on page reload
    // after submitting an invoice
    if (typeof window === 'object') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  },

  updated () {
    this.$nextTick(() => {
      if (typeof window === 'object') {
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight)
        }, 300)
      }
    })
  },

  methods: {
    goBack () {
      // on mobile let's go back after cancel/updated
      this.$router.go(-1)
    },

    goToClientBanking () {
      const user = JSON.parse(localStorage.getItem('user'))
      this.$store.commit('STORE_CLIENT_WITHOUT_BANK_MODAL', false)
      this.$router.push({
        name: 'dashboard-client-banking',
        params: {
          id: user.client.id
        }
      })
    },
  }
}
</script>

<style lang="sass">
.SubmitInvoice

  .BaseInvoiceCreate
    box-shadow: none

    .BaseInvoiceCreate__cancel-btn
      display: none

</style>
