var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "SubmitInvoice width-100" },
    [
      _c("base-invoice-create", {
        attrs: { "user-is-client": _vm.userIsClient, invoice: _vm.invoice },
        on: { cancel: _vm.goBack, updated: _vm.goBack }
      }),
      _c(
        "transition",
        { attrs: { name: "Transition__opacity-fade" } },
        [
          _vm.clientWithoutBankModal &&
          _vm.mobile.isMobile &&
          !_vm.isCurrentPageBanking
            ? _c("base-modal", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function() {
                        return [
                          _vm._v(
                            "\n        Welcome to Bobtail! Please set up your bank information.\n      "
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "base-button",
                            {
                              staticClass: "bg-blue fc-white",
                              on: { click: _vm.goToClientBanking }
                            },
                            [_vm._v("\n          Add bank account\n        ")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  3913726312
                )
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }